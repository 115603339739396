import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import "../css/Grades.css";

const query = gql`
  query getAttempts($email: String, $turma: Int) {
    turma(where: { id: { _eq: $turma }, deleted_at: { _is_null: true } }) {
      id
      registers {
        is_professor
        is_tutor
        is_team_member
        is_monitor
        is_audit
        is_evaluator
        suspended
        cancelled
        locked
        dependency
        enrollmentByEnrollment {
          person {
            first_name
            last_name
            email
            monitorandos {
              student
            }
          }
        }
      }
      lessons(
        where: {
          title: { _ilike: "Projeto%" }
          deleted_at: { _is_null: true }
          number: { _gte: 0.1, _lt: 99 }
        }
      ) {
        id
        title
        start_date
        activities(
          where: { available: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          type
          activity_completions(
            where: {
              personByPerson: {
                email: { _eq: $email }
                deleted_at: { _is_null: true }
              }
              completed: { _eq: true }
              deleted_at: { _is_null: true }
            }
          ) {
            id
            file
            personByPerson {
              id
              first_name
              last_name
              live_attendances_aggregate(
                where: {
                  lessonByLesson: {
                    turma: { _eq: $turma }
                    deleted_at: { _is_null: true }
                  }
                  deleted_at: { _is_null: true }
                }
              ) {
                aggregate {
                  count
                }
              }
            }
          }
        }
      }
      live_schedules {
        day
      }
    }
    disciplina(
      where: {
        lessons: { turma: { _eq: $turma } }
        deleted_at: { _is_null: true }
      }
      order_by: { id: asc }
    ) {
      id
      name
      lessons(
        where: {
          turma: { _eq: $turma }
          deleted_at: { _is_null: true }
          number: { _gte: 0.1, _lt: 99 }
        }
        order_by: { number: asc }
      ) {
        id
        number
        title
        start_date
        activities(
          where: { available: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          type
          activity_completions(
            where: {
              personByPerson: {
                email: { _eq: $email }
                deleted_at: { _is_null: true }
              }
              completed: { _eq: true }
            }
          ) {
            id
            personByPerson {
              id
              first_name
              last_name
            }
          }
        }
        live_attendances {
          date
          personByPerson {
            first_name
            last_name
          }
        }
        liveAttendancesByReferenceLesson {
          date
          personByPerson {
            first_name
            last_name
          }
          reference_lesson
        }
      }
    }
  }
`;

const Attendaces = observer((props) => {
  const config = {
    "Especialização FAP": {
      ignore: [
        "Aula 2 - parte 1",
        "Aula 2 - parte 2",
        "Não obrigatória",
        "Extra",
      ],
      quizanalysis: true,
      classes: ["Autoavaliação"],
    },
    "Formação FAP": {
      ignore: ["Aula 2 - parte 1", "Aula 2 - parte 2"],
      quizanalysis: true,
      classes: ["Autoavaliação"],
    },
    "Especialização ACT": {
      classes: ["Autoavaliação"],
      quizanalysis: true,
    },
    "Formação ACT": {
      classes: ["Autoavaliação"],
      quizanalysis: true,
    },
    "Curso Completo RFT": {
      quizanalysis: true,
    },
    "Módulo 1 RFT": {
      quizanalysis: true,
    },
  };

  function isBefore2023_2(turmaName) {
    const yearSemester = turmaName.split(" ").pop();
    const [year, semester] = yearSemester.split("-").map(Number);
    return year < 2023 || (year === 2023 && semester < 2);
  }

  function adjustClassesForTurma(config, turmaName) {
    if (isBefore2023_2(turmaName)) {
      const adjustedConfig = JSON.parse(JSON.stringify(config)); 
      Object.values(adjustedConfig).forEach((configItem) => {
        if (configItem.classes) {
          const classesIndex = configItem.classes.indexOf("Autoavaliação");
          if (classesIndex !== -1) {
            configItem.classes[classesIndex] = "Produzir Conteúdo"; 
          }
        }
      });
      return adjustedConfig;
    }
    return config; 
  }

  // null id will make this an Insert component
  const store = useContext(StoreContext);
  const turmaName = store.ui.turma.name
  const adjustedConfig = adjustClassesForTurma(config, turmaName);
  var variables = { turma: store.ui.turma.id };
  const course = store.ui.turma.course;
  if (!(store.admin || store.is_monitor)) variables.email = props.email;
  var { data, error, loading } = useQuery(query, { variables: variables });
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {JSON.stringify(error)}</p>;
  if (data.turma.length === 0) return <p>Turma não encontrada.</p>;
  // Filter disciplines in place to exclude 0-lesson ones (like 'Informativos')
  data.disciplina = data.disciplina.filter((d) => {
    return d.lessons.length > 0;
  });
  var titles = {};
  var live_detailed = {};
  var live_days = data.turma[0].live_schedules;
  if (store.admin || store.is_monitor) {
    data.turma[0].registers.forEach((register) => {
      let name =
        register.enrollmentByEnrollment.person.first_name +
        " " +
        register.enrollmentByEnrollment.person.last_name;
      // following was suggestd by ChatGPT
      let title = [
        register.suspended && "SUSPENSO",
        register.cancelled && "CANCELADO",
        register.locked && "Matrícula trancada",
        register.dependency && "fazendo dependência",
        register.is_tutor && "Tutor",
        register.is_monitor && "Monitor",
        register.is_professor && "Professor",
        register.is_team_member && "Equipe",
        register.is_audit && "Ouvinte",
        register.is_evaluator && "Avaliador",
      ].filter(Boolean);
      titles[name] = title.length > 0 ? ` (${title.join(", ")})` : "";
    });
  }

  //generate data for the attendances table:
  var attendances = [];
  var disciplines = [];
  var total_activities = {};
  if (
    course.includes("ACT") ||
    course.includes("FAP") ||
    course.includes("RFT")
  ) {
    disciplines.push("Aula ao vivo");
    total_activities["Aula ao vivo"] = 0;
  }

  const courseConfig = adjustedConfig[course] || {};
  const ignoreList = courseConfig.ignore || [];
  const classesList = courseConfig.classes || [];

  classesList.forEach((c) => {
    disciplines.push(c);
    total_activities[c] = 0;
  });

  data.disciplina.forEach((disciplina) => {
    let hasNonIgnoredActivities = false;
    disciplina.lessons.forEach((lesson) => {
      if (lesson.number < 0.1) return;
      lesson.activities.forEach((activity) => {
        if (!ignoreList.includes(activity.type)) {
          hasNonIgnoredActivities = true;
        }
      });
    });

    if (hasNonIgnoredActivities) {
      disciplines.push(disciplina.name);
      total_activities[disciplina.name] = 0;
    }

    disciplina.lessons.forEach((lesson) => {
      if (lesson.number < 0.1) return;
      if (lesson.number >= 0.1 && lesson.number < 99) {
        total_activities["Aula ao vivo"]++;
      }
      lesson.activities.forEach((activity) => {
        if (!ignoreList.includes(activity.type)) {
          let category = classesList.includes(activity.type)
            ? activity.type
            : disciplina.name;
          total_activities[category]++;
          activity.activity_completions.forEach((ac) => {
            var aluno =
              ac.personByPerson.first_name + " " + ac.personByPerson.last_name;
            let pos = attendances.length;
            attendances.forEach((linha, i) => {
              if (linha.nome === aluno) {
                pos = i;
              }
            });
            if (pos === attendances.length) attendances[pos] = { nome: aluno };
            if (attendances[pos][category]) {
              attendances[pos][category]++;
            } else {
              attendances[pos][category] = 1;
            }
          });
        }
      });
      if (
        store.ui.turma.course.includes("ACT") ||
        store.ui.turma.course.includes("FAP") ||
        store.ui.turma.course.includes("RFT")
      ) {
        lesson.live_attendances
          .concat(lesson.liveAttendancesByReferenceLesson)
          .forEach((at) => {
            if (at.personByPerson) {
              let aluno =
                at.personByPerson.first_name +
                " " +
                at.personByPerson.last_name;
              let pos = attendances.length;
              attendances.forEach((linha, i) => {
                if (linha.nome === aluno) {
                  pos = i;
                }
              });
              if (pos === attendances.length)
                attendances[pos] = { nome: aluno };
              if (!attendances[pos]["Aula ao vivo"])
                attendances[pos]["Aula ao vivo"] = 0;
              attendances[pos]["Aula ao vivo"]++;
              if (!live_detailed[aluno]) live_detailed[aluno] = [];
              let dates = [];
              if (at.reference_lesson && at.date) {
                let at_date = at.date.split("-").reverse();
                at_date.pop();
                dates.push(at_date.join("/"));
              } else {
                if (lesson.title?.toUpperCase().includes("WORKSHOP")) {
                  let ldate = new Date(lesson.start_date + "T12:00:00.000Z")
                    .toISOString()
                    .split("T")[0]
                    .split("-")
                    .reverse();
                  ldate.pop();
                  dates.push(ldate.join("/"));
                } else {
                  live_days.forEach(({ day }) => {
                    let start = new Date(lesson.start_date + "T12:00:00.000Z");
                    let weekday = [
                      "sun",
                      "mon",
                      "tue",
                      "wed",
                      "thu",
                      "fri",
                      "sat",
                    ].indexOf(day.toLowerCase());
                    start.setDate(
                      7 + weekday - start.getDay() + start.getDate()
                    );
                    start = start
                      .toISOString()
                      .split("T")[0]
                      .split("-");
                    dates.push(`${start[2]}/${start[1]}`);
                  });
                }
              }
              if (dates.length === 0) dates = [at.date];
              dates = dates.join(", ");
              live_detailed[aluno].push(`Lição ${lesson.number} (${dates})`);
              live_detailed[aluno].sort((a, b) => {
                a = parseFloat(a.split("Lição ")[1].split(" ")[0]);
                b = parseFloat(b.split("Lição ")[1].split(" ")[0]);
                if (a > b) return 1;
                else if (a < b) return -1;
                return 0;
              });
            }
          });
      }
    });
  });
  store.admin &&
    attendances.forEach((student) => {
      let passed = true;
      disciplines.forEach((disc) => {
        passed &&= student[disc] >= total_activities[disc] * 0.75;
      });
      student.Resultado = passed ? "Aprovado" : "Reprovado";
    });
  disciplines.forEach((name, i) => {
    disciplines[i] = {
      name,
      label: `${name} (${total_activities[name]})`,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value < total_activities[name] * 0.75 ? "red" : "black";
          return (
            <>
              <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
            </>
          );
        },
      },
    };
    if (name === "Aula ao vivo") {
      disciplines[i].options = {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value < total_activities[name] * 0.75 ? "red" : "black";
          return (
            <div
              className="tooltip"
              style={{ color: color, fontWeight: "bold" }}
            >
              {value}
              <span className="tooltiptext" id={`span${tableMeta.rowIndex}`}>
                Clique para ver as datas
              </span>
            </div>
          );
        },
      };
    }
  });
  disciplines = [
    {
      name: "nome",
      label: "nome",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value + (titles[value] || ""),
      },
    },
  ].concat(disciplines);
  store.admin &&
    disciplines.push({
      name: "Resultado",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          var color = value === "Reprovado" ? "red" : "black";
          return (
            <>
              <div style={{ color: color, fontWeight: "bold" }}>{value}</div>
            </>
          );
        },
      },
    });

  //transpose data for single person:
  if (!(store.admin || store.is_monitor)) {
    let nome = "";
    if (attendances.length > 0) {
      nome = attendances[0].nome;
      delete attendances[0].nome;
      attendances = Object.keys(attendances[0]).map((act) => {
        return {
          atividade: `${act} (${total_activities[act]})`,
          frequência: attendances[0][act],
        };
      });
    }
    disciplines = [
      {
        name: "atividade",
        label: "Disciplina (total)",
      },
      {
        name: "frequência",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div className="tooltip">
              {value <
              0.75 * total_activities[tableMeta.rowData[0].split(" (")[0]] ? (
                <b className="font-red">{value}</b>
              ) : (
                value
              )}
              {tableMeta.rowData[0].includes("Aula ao vivo") && (
                <span className="tooltiptext" id={`span${tableMeta.rowIndex}`}>
                  {live_detailed[nome] &&
                    live_detailed[nome].map((text) => (
                      <>
                        {text}
                        <br />
                      </>
                    ))}
                </span>
              )}
            </div>
          ),
        },
      },
    ];
  }
  var tableOptions = {
    //for the tables
    responsive: "scroll",
    rowsPerPageOptions: [10, 50, 200],
    rowsPerPage: 200,
    selectableRows: false,
    filterType: "multiselect",
    sortOrder: {
      name: "nome",
      direction: "asc",
    },
  };

  return (
    <>
      <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
        {(store.admin || store.is_tutor) &&
        (store.ui.turma.course.includes("ACT") ||
          store.ui.turma.course.includes("FAP") ||
          store.ui.turma.course.includes("RFT")) ? (
          <Button
            style={{ marginBottom: "10px" }}
            variant="contained"
            color="primary"
            onClick={() => props.history.push("/presenca")}
          >
            Marcar Presenças
          </Button>
        ) : (
          ""
        )}
        <MUIDataTable
          data={attendances}
          columns={disciplines}
          title={"Frequências"}
          options={{
            ...tableOptions,
            onCellClick: (_, cmeta) => {
              if (disciplines[cmeta.colIndex].name === "Aula ao vivo") {
                let text = live_detailed[attendances[cmeta.dataIndex].nome];
                if (!text) text = "";
                else text = text.join("<br />");
                let span = document.getElementById(`span${cmeta.rowIndex}`);
                if (span) span.innerHTML = text;
              }
            },
          }}
        />
      </div>
    </>
  );
});

export default Attendaces;
