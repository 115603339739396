/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { useQuery } from "react-apollo-hooks";
import { getPersonId } from "../queriesAndMutations";
import { StoreContext } from "../store";
import ListPendingActivities from "./ListPendingActivities";
import { observer } from "mobx-react-lite";
import { ClassHours } from "../queriesAndMutations";
import { Paper } from "@material-ui/core";

const WelcomeMessage = observer(() => {
  const store = useContext(StoreContext);
  const { data, error, loading } = useQuery(getPersonId, {
    variables: { email: store.email },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;

  const isRodasOrSupervisao = store.ui.turma.name.includes("Rodas") || store.ui.turma.name.includes("Supervisão");


  let message
  if (store.ui.painel.pendentes) {
    message = isRodasOrSupervisao ? videoMsg : pendingActivitiesMsg
  } else {
    message = padraoMsg
  }
  const coupon = data.person[0].coupons[0]?.code;
  return (
    <div>
      <ReactMarkdown>{message(data.person[0].first_name, store)}</ReactMarkdown>
      {(store.ui.turma.course.includes("ACT") ||
        store.ui.turma.course.includes("FAP")) && (
          <>
            {store.is_consultant && <FormLinks id={store.consultantId} />}
            {coupon && <Coupon code={coupon} />}
          </>
        )}
      <ClassTime />
      <ListPendingActivities />
    </div>
  );
});

const videoMsg = (name, store) => `Olá, ${name}!
${store.ui.turma.welcome_message}
 Veja abaixo a lista de vídeos que você ainda não assistiu

`;

const padraoMsg = (name, store) => `Olá, ${name}!
${store.ui.turma.welcome_message}
`;

const pendingActivitiesMsg = (name, store) => `Olá, ${name}!

${store.ui.turma.welcome_message}

**Você tem atividades pendentes**. Veja abaixo o *Relatório de Atividades Pendentes* para saber quais. Você pode clicar no nome da atividade para navegar diretamente para ela. E para rever o *Relatório* basta voltar aqui para o *Painel*.

`;



const ClassTime = () => {
  const store = useContext(StoreContext);
  var { data, loading, error } = useQuery(ClassHours, {
    variables: {
      personId: store.personId,
      turmaId: store.ui.turma.id,
    },
  });
  if (loading) return <p>Carregando horário...</p>;
  if (error) return <p>Erro ao carregar horário: {error.message}</p>;

  const hasSchedule = data && data.student_schedule && data.student_schedule.length > 0;

  if (!hasSchedule) {
    return null
  }

  const schedule = data.student_schedule[0].class_schedule;

  return (
    <h3 style={{ margin: "10px", padding: "10px", fontWeight: "bold" }}>Horário das aulas: {schedule}</h3>
  );
};
const Coupon = ({ code }) => (
  <Paper className="m10 p5">
    indique o curso e compartilhe seu CUPOM DE DESCONTO para seus amigos e
    receba 5% de desconto nas suas parcelas a vencer. Seu indicado receberá 5%
    de desconto no valor do curso. <br />
    Este é o seu código de desconto: <b>{code}</b>
  </Paper>
);

const FormLinks = ({ id }) => (
  <Paper className="m10 p5">
    <h2>Links dos formulários de inscrição</h2>
    <h2>ACT:</h2>
    Especializacao com bolsa:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=ACT&tipo=especializacao&semestre=2024-2&consultor=${id}&bolsa=sim`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=ACT&tipo=especializacao&semestre=2024-2&consultor=
      {id}&bolsa=sim
    </a>
    <br />
    Especializacao integral:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=ACT&tipo=especializacao&semestre=2024-2&consultor=${id}`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=ACT&tipo=especializacao&semestre=2024-2&consultor=
      {id}
    </a>
    <br />
    Formação com bolsa:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=ACT&tipo=formacao&semestre=2024-2&consultor=${id}&bolsa=sim`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=ACT&tipo=formacao&semestre=2024-2&consultor=
      {id}&bolsa=sim
    </a>
    <br />
    Formação integral:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=ACT&tipo=formacao&semestre=2024-2&consultor=${id}`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=ACT&tipo=formacao&semestre=2024-2&consultor=
      {id}
    </a>
    <br />
    <h2>FAP:</h2>
    Especializacao com bolsa:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=FAP&tipo=especializacao&semestre=2024-2&consultor=${id}&bolsa=sim`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=FAP&tipo=especializacao&semestre=2024-2&consultor=
      {id}&bolsa=sim
    </a>
    <br />
    Especializacao integral:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=FAP&tipo=especializacao&semestre=2024-2&consultor=${id}`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=FAP&tipo=especializacao&semestre=2024-2&consultor=
      {id}
    </a>
    <br />
    Formação com bolsa:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=FAP&tipo=formacao&semestre=2024-2&consultor=${id}&bolsa=sim`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=FAP&tipo=formacao&semestre=2024-2&consultor=
      {id}&bolsa=sim
    </a>
    <br />
    Formação integral:{" "}
    <a
      href={`https://aluno.relink.me/apply?curso=FAP&tipo=formacao&semestre=2024-2&consultor=${id}`}
      target="_blank"
    >
      https://aluno.relink.me/apply?curso=FAP&tipo=formacao&semestre=2024-2&consultor=
      {id}
    </a>
    <br />
    <h2>Formação em Conceituação de caso na FAP:</h2>
    Integral:
    <a href={`https://aluno.relink.me/apply?curso=em%20Conceituação%20de%20caso%20na%20FAP&tipo=formacao&semestre=2024-2&consultor=${id}`}
      target="_blank">
      https://aluno.relink.me/apply?curso=em%20Conceituação%20de%20caso%20na%20FAP&tipo=formacao&semestre=2024-2&consultor={id}
    </a>
    <br />
    Com Bolsa :
    <a href={`https://aluno.relink.me/apply?curso=em%20Conceituação%20de%20caso%20na%20FAP&tipo=formacao&semestre=2024-2&consultor=${id}&bolsa=sim`}
      target="_blank">
      https://aluno.relink.me/apply?curso=em%20Conceituação%20de%20caso%20na%20FAP&tipo=formacao&semestre=2024-2&consultor={id}&bolsa=sim
    </a>
    <br />
    <h2>RFT:</h2>
    RFT - Módulo 1 sem bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=RFT&tipo=modulo-1&semestre=2023-1&consultor=${id}`}
      target="_blank">
      https://aluno.relink.me/apply?curso=RFT&tipo=modulo-1&semestre=2023-1&consultor={id}
    </a>
    <br />
    RFT - Módulo 1 com bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=RFT&tipo=modulo-1&semestre=2023-1&consultor=${id}&bolsa=sim`}
      target="_blank">
      https://aluno.relink.me/apply?curso=RFT&tipo=modulo-1&semestre=2023-1&consultor={id}&bolsa=sim
    </a>
    <br />
    RFT - Curso completo sem bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=RFT&tipo=completo&semestre=2023-1&consultor=${id}`}
      target="_blank">
      https://aluno.relink.me/apply?curso=RFT&tipo=completo&semestre=2023-1&consultor={id}
    </a>
    <br />
    RFT - Curso completo com bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=RFT&tipo=completo&semestre=2023-1&consultor=${id}&bolsa=sim`}
      target="_blank">
      https://aluno.relink.me/apply?curso=RFT&tipo=completo&semestre=2023-1&consultor={id}&bolsa=sim
    </a>
    <br />
    <h2>ACT para Crianças e Adolescentes:</h2>
    ACT para Crianças e Adolescentes - Completo sem bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=avancado&semestre=2023-2&consultor=${id}`}>
      https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=avancado&semestre=2023-2&consultor={id}
    </a>
    <br />
    ACT para Crianças e Adolescentes - Completo com bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=avancado&semestre=2023-2&consultor=${id}&bolsa=sim`}>
      https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=avancado&semestre=2023-2&consultor={id}&bolsa=sim
    </a>
    <br />
    ACT para Crianças e Adolescentes - Básico sem bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=basico&semestre=2023-2&consultor=${id}`}>
      https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=basico&semestre=2023-2&consultor={id}
    </a>
    <br />
    ACT para Crianças e Adolescentes - Básico com bolsa:{" "}
    <a href={`https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=basico&semestre=2023-2&consultor=${id}&bolsa=sim`}>
      https://aluno.relink.me/apply?curso=Crianças%20e%20Adolescentes&tipo=basico&semestre=2023-2&consultor={id}&bolsa=sim
    </a>
    <br />
  </Paper>
);

export default WelcomeMessage;
