import React, { useState, useContext, useMemo } from "react";
import "../css/StudentSchedule.css";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { useQuery, useMutation } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";

const GET_STUDENT_SCHEDULES = gql`
  query GetStudentSchedules($turmaId: Int!) {
    student_schedule(where: { turma: { _eq: $turmaId } }) {
      id
      class_schedule
      person
    }
    person {
      id
      first_name
      last_name
    }
    live_schedule(where: { turma: { _eq: $turmaId } }) {
      day
      hour
    }
  }
`;

const UPDATE_STUDENT_SCHEDULE = gql`
  mutation UpdateStudentSchedule($id: Int!, $class_schedule: String!) {
    update_student_schedule_by_pk(
      pk_columns: { id: $id }
      _set: { class_schedule: $class_schedule }
    ) {
      id
      class_schedule
    }
  }
`;

export function StudentSchedule() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const store = useContext(StoreContext);
  const turmaId = store.ui.turma.id;

  const { loading, error, data } = useQuery(GET_STUDENT_SCHEDULES, {
    variables: { turmaId },
  });

  const weekDays = useMemo(
    () => [
      { pt: "Segunda-feira", en: "Mon" },
      { pt: "Terça-feira", en: "Tue" },
      { pt: "Quarta-feira", en: "Wed" },
      { pt: "Quinta-feira", en: "Thu" },
      { pt: "Sexta-feira", en: "Fri" },
      { pt: "Sábado", en: "Sat" },
      { pt: "Domingo", en: "Sun" },
    ],
    []
  );

  const schedulesByDay = useMemo(() => {
    if (!data || !data.live_schedule) return {};
    return data.live_schedule.reduce((acc, schedule) => {
      if (schedule.hour) {
        acc[schedule.day] = schedule.hour;
      }
      return acc;
    }, {});
  }, [data]);

  const scheduleOptions = useMemo(() => {
    const options = weekDays
      .map((day) => {
        const hour = schedulesByDay[day.en];
        return hour ? `${day.pt} - ${hour}` : null;
      })
      .filter(Boolean);
    return options;
  }, [schedulesByDay, weekDays]);

  const [updateSchedule] = useMutation(UPDATE_STUDENT_SCHEDULE);

  const openModal = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };

  const handleScheduleChange = (event) => {
    const newSchedule = event.target.value;
    if (selectedStudent) {
      setSelectedStudent({ ...selectedStudent, class_schedule: newSchedule });
    }
  };

  const saveChanges = async () => {
    try {
      await updateSchedule({
        variables: {
          id: selectedStudent.id,
          class_schedule: selectedStudent.class_schedule,
        },
      });
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const peopleMap = useMemo(() => {
    if (!data) return {};
    return data.person.reduce((acc, person) => {
      acc[person.id] = person;
      return acc;
    }, {});
  }, [data]);

  const sortedStudents = useMemo(() => {
    if (!data) return [];
    return [...data.student_schedule]
      .map((student) => ({
        ...student,
        person: peopleMap[student.person],
      }))
      .sort((a, b) => {
        const nameA = `${a.person?.first_name} ${a.person?.last_name}`.toLowerCase();
        const nameB = `${b.person?.first_name} ${b.person?.last_name}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
  }, [data, peopleMap]);

  const filteredStudents = useMemo(() => {
    return sortedStudents.filter((student) => {
      const fullName = `${student.person?.first_name} ${student.person?.last_name}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [sortedStudents, searchTerm]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar os horários: {error.message}</p>;
  if (data.student_schedule.length === 0)
    return (
      <div className="containerSchedule">Não há horários cadastrados.</div>
    );

  return (
    <div className="containerSchedule">
      <h2>Horário das Aulas</h2>
      <div className="searchContainer">
        <SearchIcon className="searchIcon" />
        <input
          type="text"
          placeholder="Pesquisar aluno"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="searchInput"
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="th">Aluno</th>
            <th className="th">Horário das aulas</th>
            <th className="th">Editar</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student) => (
            <tr key={student.id} className="tr">
              <td className="td">
                {student.person
                  ? `${student.person.first_name} ${student.person.last_name}`
                  : "N/A"}
              </td>
              <td className="td">{student.class_schedule}</td>
              <td className="td">
                <button
                  onClick={() => openModal(student)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    borderRadius: "10px",
                  }}
                >
                  <EditIcon color="primary" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal-overlay">
          <div
            className="modal-content"
            key={selectedStudent ? selectedStudent.id : "modal"}
          >
            <h3>Editar Horário</h3>
            Aluno:{" "}
            <b>
              {selectedStudent && selectedStudent.person
                ? `${selectedStudent.person.first_name} ${selectedStudent.person.last_name}`
                : "N/A"}
            </b>
            <div className="select-container">
              <select
                value={selectedStudent ? selectedStudent.class_schedule : ""}
                onChange={handleScheduleChange}
                className="custom-select"
              >
                <option value="">Selecione o horário</option>
                {scheduleOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="divbuttonsModal">
              <button onClick={saveChanges}>Salvar</button>
              <button onClick={closeModal}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
